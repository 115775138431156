import React from "react";
import PropTypes from "prop-types";

const ViewDay = ({ width = "18px", height = "18px", color = "currentColor" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    fill={color}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M2 21h19v-3H2v3zM20 8H3c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h17c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zM2 3v3h19V3H2z" />
  </svg>
);

// PropTypes validation
ViewDay.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

export default ViewDay;
