// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
/* eslint-disable prettier/prettier */

function Counters() {
  var currentYear = new Date().getFullYear() - 2002;
  var pseNeComment = `ABS është ndër kompanitë me rritje më të shpejtë në tregun e TIK në Shqipëri për ${currentYear} vitet e fundit.`;
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={234}
              suffix="+"
              title="Projekte"
              description="Të realizaur me performancë të lartë nga punonjës të certifikuar"
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={currentYear}
              suffix=""
              title="Vite"
              description={pseNeComment}
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={24}
              suffix="/7"
              title="Suport"
              description="Ekip i motivuar për t'ju asistuar në cdo problem."
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
/* eslint-enable prettier/prettier */
export default Counters;
