// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import StorageIcon from "components/Icons/StorageIcon";
import HandshakeIcon from "components/Icons/Handshake";
import PhoneMsgIcon from "components/Icons/PhoneMsgIcon";
import DevicesIcon from "components/Icons/DevicesIcon";
import TouchAppIcon from "components/Icons/TouchAppIcon";
// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKTypographyButton from "components/MKTypography/MKTypograpgyButton";
// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";
// react-router-dom components
//import { Link } from "react-router-dom";

//import ICTServices from "pages/Services/ICTServices";
function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon={<TouchAppIcon />}
                title={
                  <>
                    Shërbimet që ne ofrojmë:
                    <br />
                  </>
                }
                description="ABS synon të ndihmojë kompanitë të përmirësohen në përdorimin e Teknologjisë së Informacionit dhe Komunikimit.      "
              />
              <RotatingCardBack
                image={bgBack}
                title="Partnerët tanë  "
                description="ABS synon të përmbushë nevojat dhe kërkesat e klientëve, si dhe të arrijë standarde gjithmonë e më të larta të cilësisë dhe shërbimeve që ofron "
                action={{
                  type: "internal",
                  route: "/al/partners",
                  label: "Zbulo më shumë",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon={<StorageIcon color="#344767" />}
                  title="Shërbimet profesionale ICT"
                  description="ABS synon të ndihmojë kompanitë të përmirësohen në përdorimin e Teknologjisë së Informacionit dhe Komunikimit..."
                />
                <MKTypographyButton link="/al/services/ictservices" />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon={<HandshakeIcon color="#344767" />}
                  title="IT Outsourcing"
                  description="Qëllimi parësor i ‘’ABS outsourcing’’ është të bëhet një zgjerim i kompanisë së klientit..."
                />
                <MKTypographyButton link="/al/services/itoutsourcing" />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon={<PhoneMsgIcon color="#344767" />}
                  title="Sistemet e komonukimit të biznesit"
                  description="ABS ka si qëllim të ndërtojë rrjet të qëndrueshëm, funksional dhe me cilësi të lartë në instalim..."
                />
                <MKTypographyButton link="/al/services/business_comunication_systems" />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon={<DevicesIcon color="#344767" />}
                  title="Prodhimi media/imazh"
                  description="Në bashkëpunim me partnerë që kanë eksperiencë në prodhimet audio-video, ABS ofron një gamë të gjerë të shërbimeve të prodhimit me porosi si:Video, Reklama TV..."
                />
                <MKTypographyButton link="/al/services/media_image_production" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
