// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

/* eslint-disable prettier/prettier */

import MKBox from "components/MKBox";
//import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import routes from "routes";
// Images
import bgImage from "assets/images/itTeam_in_dataCenter.webp";
import itTeam from "assets/images/itTeam_in_dataCenter2.jpg";

import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredCard";
function BusinessComunicationSystems() {
  return (
    <>
      <MKBox
        variant="gradient"
        bgColor="white"
        shadow="sm"
        py={0.25}
        sx={{
          position: "fixed",
          top: 0,
          zIndex: 1000,
          width: "100%",
        }}
      >
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/al/contact-us",
            label: "Kontakt",
            color: "dark",
          }}
          transparent
          relative
          dark
          center
        />
      </MKBox>
      <MKBox component="header" position="relative">
        <MKBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
          sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.2),
                rgba(gradients.dark.state, 0.2)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <MKBox component="section" py={{ xs: 0, lg: 6 }} style={{ marginTop: "70px" }}>
            <Container>
              <MKBox
                style={{ marginTop: "40px" }}
                sx={{
                  borderRadius: "10px",
                  display: "inline-block",
                  background: "rgba(0, 0, 0, 0.6)",
                  padding: "15px",
                }}
              >
                {" "}
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item xs={12} md={6}>
                    <MKTypography variant="body1" color="white" opacity={0.8}>
                      <p>
                        Qëllimi i ABS është ndërtimi i rrjeteve të qëndrueshëm, me funksionim dhe
                        cilësi të lartë në instalim. Mjaft e rëndësishme për ne është edhe estetika
                        e instalimit, duke mundësuar ekspozim minimal të kabllove si dhe një
                        kombinim të mirë të pajisjeve të rrjetit. Grupi i instalimit të ABS ka
                        ekspriencë shumëvjecare në shtrirjen e kabullit duke filluar nga mjediset e
                        vogla të bizneseve deri tek hapësirat e mëdha të zyrave. Sistemet e
                        komunikimit që ABS instalon dhe mirëmban janë:
                        <ul style={{ paddingLeft: "20px" }}>
                          <li>Dhoma IT; projektim dhe integrim i Data-Center </li>
                          <li>
                            Konvergime të të dhënave dhe zërit. PABX, të dhëna/zë switches dhe
                            routers
                          </li>
                          <li>Aplikacione Video (kamera-web, CCTV, monitorim, kamera sigurie)</li>
                          <li>
                            Sisteme sigurie të integruara (alarme të sigurisë dhe zjarrit, video
                            survejimi, akses kontroll etj)
                          </li>
                          <li>Instalime me kabëll bakri dhe fibër optike</li>
                          <li>
                            Instalimi dhe/ose zhvendosja e linjave telefonike, workstation jacks,
                            linjave DSL linjave video etj.{" "}
                          </li>
                        </ul>{" "}
                        <br></br>
                      </p>
                    </MKTypography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <CenteredBlogCard
                      image={itTeam}
                      title=""
                      description=""
                      action={{
                        type: "",
                        route: "",
                        color: "",
                        label: "",
                      }}
                    />
                  </Grid>
                </Grid>
              </MKBox>
            </Container>
          </MKBox>
        </MKBox>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default BusinessComunicationSystems;
