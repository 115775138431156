// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
/* eslint-disable prettier/prettier */
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
/*
import team1 from "assets/images/system-integrator.png";
import team2 from "assets/images/bruce-mars.jpg";
import team3 from "assets/images/ivana-squares.jpg";
import team4 from "assets/images/ivana-square.jpg";
*/
function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Advanced Business Solutions
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              ofron shërbime profesionale që mundësojnë përshpejtimin e implementimeve, integrimin e
              sistemeve ICT, rritjen e besueshmërisë së tyre, uljen e kostove si dhe rritjen e
              produktivitetit të organizatave.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                /*image={team1}*/
                name="INTEGRATOR SISTEMI"
                position={{ color: "info" }}
                description={
                  <p>
                    Integrimi i sistemit përfshin planifikimin, ndërtimin, zbatimin dhe përdorimin e
                    zgjidhjeve komplekse të IT. Në kohën e zhvillimit të shpejtë dhe të menjëhershëm
                    të teknologjisë, është e domosdoshme që një kompani të jetë e përditësuar me
                    zgjidhjet më të fundit teknologjike dhe t&apos;i përdorë ato për të përmirësuar
                    efikasitetin e proceseve të biznesit. Nëse doni të implementoni, përmirësoni,
                    ristrukturoni sistemet tuaja ICT ose doni t&apos;i ndërtoni ato nga fillimi,
                    ekspertët tanë do të analizojnë kërkesat dhe nevojat e biznesit tuaj, sistemin
                    tuaj ekzistues dhe në bazë të rezultateve të mbledhura, ne do t&apos;ju
                    propozojmë zgjidhjet më optimale të integrimit brenda investimit tuaj.<br></br>{" "}
                    <br></br>{" "}
                  </p>
                }
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                //image={team2}
                name="DATA CENTER COMPUTING"
                position={{ color: "info" }}
                description={
                  <p>
                    ABS zhvillon strategji për ndërtimin, operimin, zhvillimin e proҫeseve dhe
                    infrastrukturës së një data center. ABS mundëson përdorimin e pajisjeve të
                    fuqishme të administrimit, për të automatizuar detyrat organizuese dhe
                    administrative, Në mënyrë që të përfitojë prej aplikacioneve të bazës së të
                    dhënave, dhe paketave ERP. Shërbimet dhe zgjidhjet tona përfshijnë:
                    <ul style={{ marginLeft: "20px" }}>
                      <li>Analiza, planifikime, projektime and optimizime të data-center</li>
                      <li>Prokurimin dhe zhvillimin e Server dhe Storage</li>
                      <li>Virtualizimin</li>
                      <li>Mbrojtjen e të dhënave</li>
                      <li>Dhoma të serverave</li>
                    </ul>
                  </p>
                }
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                //image={team4}
                name="PLANIFIKIM I RIMËKËMBJES NDAJ FATKEQËSIVE, DIZAJN I SIGURISË DHE BACKUP"
                position={{ color: "info" }}
                description={
                  <p>
                    ABS asiston duke marrë masa paraprake në rast katastrofe, duke ndihmuar
                    kompaninë të rimëkëmbet. Ekipi ynë punon me klientët për ndërtimin e një rutine
                    Backup-i për të mbrojtur humbjen apo dëmtimin e të dhënave. Ne sigurojmë
                    shërbime sigurie gjithëpërfshirëse për të mbrojtur informacione të rëndësishme
                    të klientit, duke përfshirë këtu firewall, antivirus dhe zgjidhje anti-spam.
                    Gjithashtu sigurojmë monitorim IT për të kryer mirëmbajtje dhe mbrojtje
                    parandaluese të rrjetit nga kërcënimet e jashtme
                    <br></br>
                    <br></br>
                  </p>
                }
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                //image={team3}
                name=" MENAXHIMI I PROJEKTIT DHE KONSULTIMI"
                position={{ color: "info" }}
                description={
                  <p>
                    Grupi i Projektit dhe Konsultimit është i fokusuar në menaxhimin e projekteve të
                    mëdha të sistemeve të integruara dhe në mbikëqyrjen e zgjidhjeve të ndryshme. Ky
                    grup ofron gjithashtu konsultime për kompani të ndryshme duke parë për software
                    design, menaxhimin e bazës së të dhënave, konsultimin dhe dizenjimin e faqeve
                    web si dhe ofrimin e zgjidhjeve në zonat IT. Grupi ynë është i përfshirë në cdo
                    fazë të zhvillimit të një projekti. Përvec kësaj, ABS synon të implementojë
                    zgjidhje pa ndikuar në ecurinë e proceseve të biznesit.
                    <br></br> <br></br> <br></br>
                  </p>
                }
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                //image={team4}
                name=" ZHVILLIMI I APLIKACIONIT, INSTALIMI, PËRSHTATJA AND MIRËMBAJTJA"
                position={{ color: "info" }}
                description={
                  <p>
                    Një ekip i aftë dhe i dedikuar fokusohet plotësisht për të kuptuar kërkesat e
                    aplikimit të klientit, përgatit arkitekturën, zhvillon, teston dhe në fund e
                    vendos atë në zbatim. Ne mbështetemi në praktikat standarte të zhvillimit të
                    software-it dhe testimit me rigorozitet të aplikacioneve për të siguruar
                    eleminimin e defekteve
                  </p>
                }
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                //image={team4}
                name="WEBSITE DESIGN DHE ZGJIDHJE NË ZHVILLIM"
                position={{ color: "info" }}
                description={
                  <p>
                    Për dizenjimin dhe zhvillimin e një website-i, ekipi ynë siguron një zgjidhje të
                    plotë: duke zhvilluar përmbajtje, dizenjim, programim dhe hostim web-friendly.
                    Cfarëdo madhësie të ketë website-i, duke filluar nga një faqe e thjeshte statike
                    tek një faqe komplekse, ne sigurojmë për klientët tanë zgjidhjet më të mira për
                    të plotësuar nevojat e tyre.
                  </p>
                }
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                //image={team4}
                name="AUDITIMI I SISTEMIT IT"
                position={{ color: "info" }}
                description={
                  <p>
                    Një numër i madh i menaxherëve të biznesit nuk arrijnë të kuptojnë
                    infrastrukturat e tyre IT. ABS u siguron një rishikim të asaj cfarë kanë, si
                    është duke punuar për ta dhe cfarë nevojitet të përmirësohet. Auditimi është
                    gjithashtu i dobishëm për verifikimin e konfidencialitetit dhe integrimit të
                    sistemeve.
                  </p>
                }
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
