/* eslint-disable prettier/prettier */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
//import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
//import nasaLogo from "assets/images/logos/gray-logos/logo-nasa.svg";
//import vodafoneLogo from "assets/images/logos/gray-logos/logo-vodafone.svg";
//import digitalOceanLogo from "assets/images/logos/gray-logos/logo-digitalocean.svg";
import schneider from "assets/images/logos/gray-logos/schneider2.jpg";
import hpe from "assets/images/logos/gray-logos/hpe.jpg";
import cisco from "assets/images/logos/gray-logos/cisco.png";
import veeam from "assets/images/logos/gray-logos/veeam.png";
import microsoft from "assets/images/logos/gray-logos/microsoft.png";
import aruba from "assets/images/logos/gray-logos/aruba.png";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Partner me më shumë se </MKTypography>
          <MKTypography
            variant="h2"
            color="info"
            textGradient
            mb={2}
            style={{ paddingLeft: "10px" }}
          >
            56 kompani
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}></MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="AKSHI"
              date="1 day ago"
              review="Infrastruktura e shkëlqyeshme dhe zbatimi i teknologjisë së fundit kanë ofruar biznesit tonë besueshmëri.Të dhënat në kohë reale mbi shfrytëzimin e burimeve, kohët e përgjigjes dhe metrikat e tjera kyçe kanë fuqizuar që të marrim vendime të informuara, të optimizojmë operacionet tona dhe të planifikojmë për rritje të ardhëshme."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="PLAZA"
              date="1 week ago"
              review="Fleksibiliteti i ofruar nga arkitektura e projektit ka mundësuar që të ndryshojmë lehtësisht kërkesat e ndryshme,të shtojmë servera të rinj ose alokojmë burime në çast. Koha e përgjigjes dhe metrikat e tjera kyçe kanë optimizuar operacionet tona dhe na kane ndihmuar të planifikojmë për rritje të ardhëshme. "
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="BKT"
              date="3 weeks ago"
              review="Një aspekt që dallohet thelbësisht në datacenter-in tone është dizajni efikas i energjisë.Zgjidhjet inovative për ftohjen dhe teknikat e optimizuara të menaxhimit të energjisë jo vetëm kanë kontribuar në një mjedis më të pastër, por kanë sjellë edhe ulje të konsiderueshme të kostos për organizatën tonë."
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={schneider} alt="schneider" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={hpe} alt="hpe" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={cisco} alt="cisco" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={veeam} alt="veeam" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={microsoft} alt="microsoft" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={aruba} alt="aruba" width="100%" opacity={0.6} />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
