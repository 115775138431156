import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
//import { stopReportingRuntimeErrors } from "react-error-overlay";
//stopReportingRuntimeErrors();
const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);
console.log = () => {};
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
