// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
//import MKAvatar from "components/MKAvatar";
//import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
//import profilePicture from "assets/images/bruce-mars.jpg";
import { useTheme } from "@mui/material/styles";

import useMediaQuery from "@mui/material/useMediaQuery";
import bgImage from "assets/images/examples/staff.png";

function Profile() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(""));

  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <MKBox
          width="100%"
          bgColor="white"
          borderRadius="xl"
          shadow="xl"
          mb={6}
          sx={{ overflow: "hidden" }}
        >
          <Grid container spacing={isSmallScreen ? 2 : 4}>
            <Grid item xs={12} md={6}>
              <img
                src={bgImage}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt="Description of the image"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MKTypography
                variant="body1"
                fontWeight="light"
                color="text"
                style={{ padding: "20px" }}
              >
                {" "}
                <br /> <br />
                ABS i kushton rëndësi të veçantë proçesit të përzgjedhjes së stafit të saj, duke
                konsideruar përgatitjen, pasionin për punën, entizuazmin e tyre dhe aftësinë për
                punë në grup si faktor kryesor të suksesit të kompanisë. Numrin më të madh të të
                punësuarve e përbëjnë inxhinierë të kualifikuar të ICT. Sekreti i progresit dhe
                rritja konstante është e lidhur me talentin, pasionin dhe vullnetin e fortë për
                sukses të punojnësve të saj! <br />
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
      </Container>
    </MKBox>
  );
}

export default Profile;
