import React from "react";
import PropTypes from "prop-types";

const DashboardIcon = ({ width = "18px", height = "18px", color = "currentColor" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="3" width="8" height="8" fill={color} />
    <rect x="13" y="3" width="8" height="8" fill={color} />
    <rect x="3" y="13" width="4" height="8" fill={color} />
    <rect x="9" y="13" width="12" height="8" fill={color} />
  </svg>
);

// PropTypes validation
DashboardIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

export default DashboardIcon;
