// @mui icons
/*
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";
*/
// Material Kit 2 React components
import MKTypography from "components/MKTypography";
/* eslint-disable prettier/prettier */
// Images
import logoCT from "assets/images/abslogo-footer.png";

//const date = new Date().getFullYear();

export default {
  brand: {
    name: "Advanced Business Solutions",
    image: logoCT,
    route: "/",
  },
  socials: [
    /*
    {
      icon: <FacebookIcon />,
      link: "#",
    },
    {
      icon: <TwitterIcon />,
      link: "#",
    },
    {
      icon: <GitHubIcon />,
      link: "#",
    },
    {
      icon: <YouTubeIcon />,
      link: "#",
    },*/
  ],
  menus: [
    {
      name: "Kompania",
      items: [
        { name: "rreth nesh", href: "/al/rreth-nesh" },
        { name: "Stafi ABS", href: "/al/stafiABS" },
        { name: "Pse ABS?", href: "/al/pse_ne" },
      ],
    },
    {
      name: "Shërbime",
      items: [
        { name: "Shërbime profesionale  ICT", href: "/al/services/ictservices" },
        { name: "IT Outsourcing", href: "/al/services/itoutsourcing" },
        {
          name: "Sistemet e komunikimit të bizensit",
          href: "/al/services/business_comunication_systems",
        },
      ],
    },
    {
      name: "Informacion",
      items: [
        { name: "Kontakt", href: "/al/contact-us" },
        { name: "Partnerët", href: "/al/partners" },
        { name: "Projektet", href: "/al/projekte" },
      ],
    },
    {
      name: "Adresa",
      items: [
        {
          name: "“Murat Toptani” Str.Eurocol Center",
          href: "https://www.google.com/maps/place/Eurocol+Business+Center/@41.3266426,19.8211927,19z/data=!4m14!1m7!3m6!1s0x1350311a7dcbf711:0x43685b5b3f0013d6!2sEurocol+Business+Center!8m2!3d41.3266426!4d19.8218364!16s%2Fg%2F1tf841nj!3m5!1s0x1350311a7dcbf711:0x43685b5b3f0013d6!8m2!3d41.3266426!4d19.8218364!16s%2Fg%2F1tf841nj?entry=ttu",
        },
        { name: "8th Floor,1001, Tirana,Albania" },
        { name: "Tel: +355 4 227 4672" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; 2017 Advanced Business Solutions.
    </MKTypography>
  ),
};
