/* eslint-disable prettier/prettier */
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/AboutUsCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import post1 from "assets/images/examples/camera1.jpg";
import post2 from "assets/images/examples/fc2.jpg";
import post3 from "assets/images/examples/it-support-1.jpg";
import post4 from "assets/images/examples/career-training-usa.jpg";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={12}>
          <MKTypography variant="h3" mb={6}>
            PAVARËSISHT SE NË CILËN INDUSTRI BËNI PJESË, ABS DO TË MARRË PËRSIPËR CDO SFIDË PËR T’JU
            MUNDËSUAR REALIZIMIN E CDO NEVOJE!
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post1}
              title="Zgjidhje të avancuara"
              description="ABS ofron zgjidhje të avancuara të TIK-ut dhe mbështetje për bizneset, agjencitë qeveritare dhe organizatat OJQ."
              action={{}}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post2}
              title="Efikasitet i larte"
              description="Zgjidhjet që ne ofrojmë u japin klientëve akses në mjetet moderne të TIK-ut dhe i ndihmojnë ata të rrisin efektivitetin dhe efikasitetin e biznesit të tyre."
              action={{
                type: "",
                route: "",
                color: "",
                label: "",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post3}
              title="Suport i perhershem"
              description="Shërbimet e suportit u japin atyre garanci për funksionimin e plotë të sistemeve TIK të instaluar"
              action={{}}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <BackgroundBlogCard
              image={post4}
              title="Staf i kualifikuar"
              description="Sekreti i progresit dhe rritja konstante e ABS është e lidhur me talentin, pasionin dhe vullnetin e fortë për sukses të punojnësve të saj!"
              action={{
                type: "",
                route: "",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
