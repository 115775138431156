// @mui material components

/* eslint-disable prettier/prettier */

/* eslint-disable no-unused-vars */
// @mui icons
//import GitHubIcon from "@mui/icons-material/GitHub";
// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
//import ContactUs from "layouts/al/contact-us";
import Author from "layouts/pages/landing-pages/author";

import Icon from "@mui/material/Icon";

import ICTServices from "layouts/pages/Services/ICTServices";
import ITOutsourcing from "layouts/pages/Services/ITOutsourcing";
import BusinessComunicationSystems from "layouts/pages/Services/BusinessComunicationSystems";
import WhyUs from "pages/LandingPages/WhyUs";
import Maintenance from "pages/LandingPages/Maintenance";
import Partners from "pages/Partners/Partners";
import MediaImageProduction from "layouts/pages/Services/MediaImageProduction";
import Contact from "pages/LandingPages/Author/sections/Contact";
import Projects from "pages/Projects";
import Plaza from "layouts/pages/Projects/Plaza";
import BKT from "layouts/pages/Projects/BKT";
import MAS from "layouts/pages/Projects/MAS";
import Oshee from "layouts/pages/Projects/Oshee";
import MFE from "layouts/pages/Projects/MFE";
import Rash from "layouts/pages/Projects/Rash";
import AKSHI from "layouts/pages/Projects/AKSHI";

import PDFViewer from "layouts/pages/Services/Telecomunication/PDFViewer";
import tarifasPdf from "layouts/pages/Services/tarifat.pdf";
import treguesitPdf from "layouts/pages/Services/treguesit.pdf";
import kontrataPdf from "layouts/pages/Services/kontrata.pdf";
import DashboardIcon from "assets/icons/dashboard.svg";
import DashboardIcon2 from "components/Icons/Dashboard";
import ViewDay from "components/Icons/ViewDay";
import PeopleIcon from "components/Icons/People";
import AccountTreeIcon from "components/Icons/AccountTree";
import PublicIcon from "components/Icons/PublicIcon";
import Presentation from "pages/Presentation";
const routes = [
  {
    name: "Rreth nesh",
    icon: <DashboardIcon2 />,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        //name: "landing pages",
        collapse: [
          {
            name: "Rreth nesh",
            route: "/al/rreth-nesh",
            component: <AboutUs />,
          },
          {
            name: "Stafi i ABS",
            route: "/al/stafiABS",
            component: <Author />,
          },
          {
            name: "Pse ne?",
            route: "/al/pse_ne",
            component: <WhyUs />,
          },
        ],
      },
    ],
  },
  {
    name: "Produkte dhe sherbime",
    icon: <ViewDay />,
    collapse: [
      {
        name: "Produkte",
        description: "",
        dropdown: true,
        collapse: [
          /*{
            name: "Produkte",
            route: "#",
            component: <PageHeaders />,
          },*/
          {
            name: "Shërbime profesionale  ICT",
            route: "/al/services/ictservices",
            component: <ICTServices />,
          },
          {
            name: "IT Outsourcing",
            route: "/al/services/itoutsourcing",
            component: <ITOutsourcing />,
          },
          {
            name: "Sistemet e komunikimit të bizensit",
            route: "/al/services/business_comunication_systems",
            component: <BusinessComunicationSystems />,
          },
          {
            name: "Prodhimi media/imazh",
            route: "/al/services/media_image_production",
            component: <MediaImageProduction />,
          },
        ],
      },
      {
        name: "Telekomunikacion",
        description: "",
        dropdown: true,
        collapse: [
          {
            name: "tarifat",
            route: "/al/tarifat",
            component: <PDFViewer pdfUrl={tarifasPdf} />,
          },
          {
            name: "treguesi QoS",
            route: "/al/treguesit",
            component: <PDFViewer pdfUrl={treguesitPdf} />,
          },
          {
            name: "Kontratë Pajtimtari",
            route: "/al/kontrata",
            component: <PDFViewer pdfUrl={kontrataPdf} />,
          },
        ],
      } /*
      {
        name: "input areas",
        description: "See all input areas",
        dropdown: true,
        collapse: [
          {
            name: "inputs",
            route: "/sections/input-areas/inputs",
            component: <Inputs />,
          },
          {
            name: "forms",
            route: "/sections/input-areas/forms",
            component: <Forms />,
          },
        ],
     
      },*/,
    ],
  },
  {
    name: "Partnerët",
    icon: <PeopleIcon />,
    route: "/al/partners",
    component: <Partners />,
  },
  {
    name: "Projekte",
    icon: <AccountTreeIcon />,
    route: "/al/projekte",
    component: <Projects />,
  },
  {
    name: "",
    route: "/al/contact-us",
    component: <Contact />,
  },
  {
    route: "/al/project/plaza",
    component: <Plaza />,
  },
  {
    type: "",
    name: "",
    route: "/al/project/bkt",
    component: <BKT />,
  },
  {
    type: "",
    name: "",
    route: "/al/project/mas",
    component: <MAS />,
  },
  {
    type: "",
    name: "",
    route: "al/project/oshee",
    component: <Oshee />,
  },
  {
    type: "",
    name: "",
    route: "al/project/mfe",
    component: <MFE />,
  },
  {
    type: "",
    name: "",
    route: "al/project/rash",
    component: <Rash />,
  },
  {
    type: "",
    name: "",
    route: "al/project/akshi",
    component: <AKSHI />,
  },
  {
    type: "",
    name: "",
    route: "/en/",
    component: <Maintenance />,
  },
];

export default routes;
