/* eslint-disable prettier/prettier */

import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";
// Images
import bgImage from "assets/images/dataCenter4.webp";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import DefaultFooter from "examples/Footers/DefaultFooter";

import footerRoutes from "footer.routes";

import MKTypography from "components/MKTypography";

import schneider from "assets/images/logos/gray-logos/schneider2.jpg";
import hpe from "assets/images/logos/gray-logos/hpe.jpg";
import cisco from "assets/images/logos/gray-logos/cisco.png";
import veeam from "assets/images/logos/gray-logos/veeam.png";
import microsoft from "assets/images/logos/gray-logos/microsoft.png";
import aruba from "assets/images/logos/gray-logos/aruba.png";
import hp from "assets/images/logos/gray-logos/hp.png";
import oracle from "assets/images/logos/gray-logos/oracle.png";
import vmware from "assets/images/logos/gray-logos/vmware.png";
import lenovo from "assets/images/logos/gray-logos/lenovo.png";
import dell from "assets/images/logos/gray-logos/dell.png";
import pelco from "assets/images/logos/gray-logos/pelco.png";
import checkpoint from "assets/images/logos/gray-logos/checkpoint.png";
import nexans from "assets/images/logos/gray-logos/nexans.png";
import sony from "assets/images/logos/gray-logos/sony.png";

import * as React from "react";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link component from React Router
import "../../card.css";
import { useState } from "react";
function Partners() {
  const [isHovered, setIsHovered] = useState(false);
  console.log(isHovered);
  /*
  const zoomImageStyle = {
    transition: "transform 0.2s ease",
    transform: isHovered ? "scale(2.1)" : "scale(1)",
    border: isHovered ? "4px" : "0px",
  };*/
  return (
    <>
      <MKBox
        variant="gradient"
        bgColor="white"
        shadow="sm"
        py={0.25}
        sx={{
          position: "fixed",
          top: 0,
          zIndex: 1000,
          width: "100%",
        }}
      >
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/al/contact-us",
            label: "Kontakt",
            color: "dark",
          }}
          transparent
          relative
          dark
          center
        />
      </MKBox>
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <MKTypography variant="h1" fontWeight="bold" color="white" mb={1}>
            Partnerët tanë
          </MKTypography>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Divider sx={{ my: 6 }} />
          <Grid container spacing={3} justifyContent="center">
            <div className="card-container" style={{ margin: "20px" }}>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  component={Link}
                  to="https://www.se.com/ww/en/"
                  target="_blank"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CardMedia className="card" component="img" height="140" image={schneider} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Schneider
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  component={Link}
                  to="https://www.hpe.com/us/en/home.html"
                  target="_blank"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CardMedia className="card" component="img" height="140" image={hpe} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Hewlett Packard Enterprise
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  component={Link}
                  to="https://www.microsoft.com/"
                  target="_blank"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CardMedia className="card" component="img" height="140" image={microsoft} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Microsoft
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  component={Link}
                  to="https://www.arubanetworks.com/"
                  target="_blank"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CardMedia className="card" component="img" height="140" image={aruba} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Aruba
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  component={Link}
                  to="https://www.cisco.com/"
                  target="_blank"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CardMedia className="card" component="img" height="140" image={cisco} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Cisco
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>

              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  component={Link}
                  to="https://www.veeam.com/"
                  target="_blank"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CardMedia className="card" component="img" height="140" image={veeam} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Veeam
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  component={Link}
                  to="https://www.oracle.com/"
                  target="_blank"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CardMedia className="card" component="img" height="140" image={oracle} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Oracle
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  component={Link}
                  to="https://www.hp.com/us-en/home.html"
                  target="_blank"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CardMedia className="card" component="img" height="140" width="320" image={hp} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      HP
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  component={Link}
                  to="https://www.vmware.com/"
                  target="_blank"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CardMedia className="card" component="img" height="140" image={vmware} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Vmware
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  component={Link}
                  to="https://www.lenovo.com/"
                  target="_blank"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CardMedia className="card" component="img" height="140" image={lenovo} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Lenovo
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  component={Link}
                  to="https://www.checkpoint.com/"
                  target="_blank"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CardMedia className="card" component="img" height="140" image={checkpoint} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Check Point
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  component={Link}
                  to="https://www.dell.com/"
                  target="_blank"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CardMedia className="card" component="img" height="140" image={dell} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      DELL
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  component={Link}
                  to="https://www.pelco.com/"
                  target="_blank"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CardMedia className="card" component="img" height="140" image={pelco} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Pelco
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  component={Link}
                  to="https://www.sony.com/"
                  target="_blank"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CardMedia className="card" component="img" height="140" image={sony} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Sony
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea
                  component={Link}
                  to="https://www.nexans.com//"
                  target="_blank"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <CardMedia className="card" component="img" height="140" image={nexans} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Nexans
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          </Grid>
        </Card>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}
export default Partners;
