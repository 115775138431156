import React from "react";
import PropTypes from "prop-types";
/*
import tarifasPdf from "layouts/pages/Services/tarifat.pdf";
function PDFViewer() {
  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
      <iframe
        src={tarifasPdf}
        width="100%"
        height="100%"
        style={{ border: "none" }}
        title="PDF Viewer"
      ></iframe>
    </div>
  );
}*/

function PDFViewer({ pdfUrl }) {
  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
      <iframe
        src={pdfUrl}
        width="100%"
        height="100%"
        style={{ border: "none" }}
        title="PDF Viewer"
      ></iframe>
    </div>
  );
}
PDFViewer.propTypes = {
  pdfUrl: PropTypes.string.isRequired,
};
export default PDFViewer;
