// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Link from "@mui/material/Link";
//import Stack from "@mui/material/Stack";
/* eslint-disable prettier/prettier */
// Material Kit 2 React components
import MKBox from "components/MKBox";
//import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import routes from "routes";
// Images
import bgImage from "assets/images/media.jpg";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

function MediaImageProduction() {
  return (
    <MKBox component="header" position="relative">
      <MKBox
        variant="gradient"
        bgColor="white"
        shadow="sm"
        py={0.25}
        sx={{
          position: "fixed",
          top: 0,
          zIndex: 1000,
          width: "100%",
        }}
      >
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/al/contact-us",
            label: "Kontakt",
            color: "dark",
          }}
          transparent
          relative
          dark
          center
        />
      </MKBox>
      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.4)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container style={{ paddingTop: "100px" }}>
          <Grid
            paddingTop="10px"
            container
            item
            xs={12}
            md={7}
            lg={6}
            flexDirection="column"
            justifyContent="center"
          >
            <MKTypography
              variant="h2"
              color="white"
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Prodhimi Media/Imazh
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} pr={6} mr={6}>
              <p>
                Në bashkëpunim me partnerë që kanë eksperiencë në prodhimet audio-video, ABS ofron
                një gamë të gjerë të shërbimeve të prodhimit me porosi duke përfshirë:
                <ul style={{ paddingLeft: "20px" }}>
                  <li>Reklama TV & Radio. Video për web-in. </li>
                  <li>Dizenjim website</li>
                  <li>
                    Dizajne të logove, kartave të biznesit, fletëpalosjeve, menuve, posterave,
                    ftesave, broshurave, etj.
                  </li>
                  <li>Dokumentarë</li>
                  <li>CD-ROM & DVDs interaktive</li>
                  <li>Udhëzime-Audio dhe Regjistrime Audio</li>
                </ul>{" "}
              </p>
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </MKBox>
  );
}

export default MediaImageProduction;
