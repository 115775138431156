/* eslint-disable prettier/prettier */
import MKBox from "components/MKBox";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Kit 2 React components

//import MKAvatar from "components/MKAvatar";
//import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

/*

import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
*/
// Images
import bgImage from "assets/images/examples/data center 5.jpg";

// Images

function Profile() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(""));

  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <MKBox
          width="100%"
          bgColor="white"
          borderRadius="xl"
          shadow="xl"
          mb={6}
          sx={{ overflow: "hidden" }}
        >
          <Grid container spacing={isSmallScreen ? 2 : 4}>
            <Grid item xs={12} md={6}>
              <img
                src={bgImage}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt="Description of the image"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MKTypography
                variant="body1"
                fontWeight="light"
                color="text"
                style={{ padding: "20px" }}
              >
                ABS ltd u themelua në vitin 2002 nga një grup shqiptar inxhinierësh me eksperiencë
                në fushën e Teknologjive Kompjuterike dhe Telekomunikacionit. Në vitet e hershme të
                aktivitetit të saj, ABS u fokusua në PABX, networkun dhe telekomunikacionin, duke u
                bërë lideri i tregut në Shqipëri në këtë fushë. ABS ka instaluar dhe mirëmbajtur
                infrastrukturën e rrjetit dhe PABX për pjesën më të madhe të institucioneve
                qeveritare, ambasadave, organizatave ndërkombëtare dhe bankave që veprojnë në
                Shqipëri. Tregtimi dhe rishitja e kompjuterëve, serverëve dhe pajisjeve të markave
                të njohura mbarëbotërore, së bashku me shërbimet profesionale të TIK-ut u prezantuan
                nga ABS në 2007. Këto u pasuan më pas nga shërbime outsource për disa kompani
                prestigjioze kombëtare dhe ndërkombëtare. <br />
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
      </Container>
    </MKBox>
  );
}

export default Profile;
