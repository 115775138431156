/* eslint-disable no-unused-vars */
import React, { useState, useRef } from "react";
import { Popper, Grow, Box, Typography } from "@mui/material";
import PublicIcon from "components/Icons/PublicIcon";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
const LanguageDropdown = () => {
  const [arrowRef, setArrowRef] = useState(null);
  const [isLanguageDropdownVisible, setIsLanguageDropdownVisible] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
  const dropdownRef = useRef(null);
  let hideTimeout = useRef(null);

  const handleMouseEnterIcon = (event) => {
    if (hideTimeout.current) {
      clearTimeout(hideTimeout.current);
    }
    setDropdownAnchorEl(event.currentTarget);
    setIsLanguageDropdownVisible(true);
  };

  const handleMouseEnterDropdown = () => {
    if (hideTimeout.current) {
      clearTimeout(hideTimeout.current);
    }
    setIsLanguageDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    hideTimeout.current = setTimeout(() => {
      setIsLanguageDropdownVisible(false);
    }, 200); // Short delay to avoid flickering
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      onMouseEnter={handleMouseEnterIcon}
      onMouseLeave={handleMouseLeave}
    >
      {/* PublicIcon triggers dropdown visibility on hover */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginLeft: "15px",
        }}
      >
        <PublicIcon color="#344767" />
      </div>

      {isLanguageDropdownVisible && (
        <Popper
          anchorEl={dropdownAnchorEl}
          open={isLanguageDropdownVisible}
          placement="bottom-start"
          transition
          style={{ zIndex: 10, width: "7rem" }}
          modifiers={[
            {
              name: "arrow",
              enabled: true,
              options: {
                element: dropdownRef.current,
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "left top",
                background: "#ffffff",
                marginTop: "19px",
              }}
              onMouseEnter={handleMouseEnterDropdown} // Keep dropdown visible on hover
              onMouseLeave={handleMouseLeave} // Hide with a delay
            >
              <MKBox borderRadius="lg" boxShadow={3} p={2} mt={1}>
                <MKTypography
                  key="eng"
                  component={Link}
                  to="/en" // This sets the route to redirect to
                  minWidth="1.25rem"
                  display="block"
                  variant="button"
                  color="black"
                  textTransform="capitalize"
                  fontWeight="regular"
                  py={0.625}
                  px={2}
                  sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                    borderRadius: borderRadius.md,
                    cursor: "pointer",
                    transition: "all 300ms linear",

                    "&:hover": {
                      backgroundColor: grey[200],
                      color: dark.main,
                    },
                  })}
                >
                  English
                </MKTypography>

                <MKTypography
                  key="eng"
                  component={Link}
                  to="/" // This sets the route to redirect to
                  minWidth="1.25rem"
                  display="block"
                  variant="button"
                  color="black"
                  textTransform="capitalize"
                  fontWeight="regular"
                  py={0.625}
                  px={2}
                  sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                    borderRadius: borderRadius.md,
                    cursor: "pointer",
                    transition: "all 300ms linear",

                    "&:hover": {
                      backgroundColor: grey[200],
                      color: dark.main,
                    },
                  })}
                >
                  Shqip
                </MKTypography>
              </MKBox>
            </Grow>
          )}
        </Popper>
      )}
    </div>
  );
};

export default LanguageDropdown;
