/* eslint-disable prettier/prettier */
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import itTeam from "assets/images/it team.jpg";
import PublicIcon from "components/Icons/PublicIcon";
import PaymentsIcon from "components/Icons/PaymentsIcon";
import ComputerIcon from "components/Icons/ComputerIcon";
import AppIcon from "components/Icons/AppIcon";
import Settings from "components/Icons/Settings";
import Dashboard from "components/Icons/Dashboard";
function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} lg={8}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon={<PublicIcon color="#344767" />}
                    title="Suportim Desktop-i"
                    description="Nga blerja e produktit deri tek shitja, teknikët e desktop-it të ABS mund të suportojnë gjithë ciklin e jetës të aseteve tuaja IT,
                     duke siguruar shërbime të personalizuara të desktop-it bazuar në nuancat e mjedisit tuaj dhe aftësive teknike të përdoruesve tuaj. 
                     Ne sigurojmë Shërbime të Menaxhimit të Desktop-it për Instalime / Lëvizje / Shtime / Ndryshime (IMAC). Kërkon të lëvizësh në një zyrë të re?
                      Një system të ri operimi? Kërkon ti mbash pajisjet e tua të përditësuara? Transferim të të dhënave? Zgjedhjen e një notebook-u të duhur për ju? 
                      Përcaktimin e një strategjie për zëvendësimin e një software? ABS i ofron të gjitha këto për ju. Ne dokumentojmë cdo proҫes për të siguruar qëndrueshmëri dhe saktësi."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon={<PaymentsIcon color="#344767" />}
                    title="Siguria IT: Operacioni i Rrjetit"
                    description="Rrjetet IT janë duke u bërë më komplekse nga dita në ditë, mirëmbajtja e tyre mund të jetë shumë sfiduese, prandaj ‘’outsourcing’’ për ABS është një alternativë e besueshme për shkak të specializimit në këtë fushë, duke fituar akses në një numër të madh të veҫorive të vendosura në një ҫmim të vetëm. Menaxhimi i rrjetit përfshin planifikimin e dizajnit të rrjetit, mirëmbajtjen e hardware dhe software të rrjetit, integrimin e tyre, fuqizimin e serverave për të qëndruar krahas me zhvillimet e fundit, mbikqyrjes së burimeve dhe mbrojtjes së rrjetit nga hakerat."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon={<ComputerIcon color="#344767" />}
                    title="Shërbimet Help desk"
                    description="Nëpërmjet Help Desk ne ofrojmë mbështetje në kohë reale për të ndihmuar në zgjidhjen e shpejtë të ҫdo cështje. ABS përdor teknologji në largësi të avancuara që na lejojnë të adresojmë menjëherë cështje në lidhje me kompjuterat, perferikët, PDA, serverat, switchet si dhe cështjet software."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon={<AppIcon color="#344767" />}
                    title="Zhvillimin dhe mirëmbajtjen e Aplikacionit"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginTop: "10px" }}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon={<Settings color="#344767" />}
                    title="Shërbimet e rimëkëmbjes nga fatkeqësitë"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginTop: "10px" }}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon={<Dashboard color="#344767" width="30px" height="30px" />}
                    title="Website Design dhe Zgjidhjet zhvilluese"
                    description=""
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={itTeam}
              title=""
              description="Ne mbajmë në përditësim operimet e biznesit IT. Ekipi ynë personalizon shërbimet IT për secilin nga klientët. "
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "",
                label: "",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
