/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable prettier/prettier */
import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
// Custom styles for MKTypography
import MKTypography from "components/MKTypography/index";
import ArrowBackIcon from "components/Icons/ArrowBackIcon";
function MKTypographyBack(props) {
  MKTypographyBack.propTypes = {
    link: PropTypes.string.isRequired,
  };
  return (
    <MKTypography
      component="a"
      href={props.link}
      rel="noreferrer"
      variant="body2"
      color="black"
      fontWeight="regular"
      sx={{
        display: "flex",
        alignItems: "center",

        "& .material-icons-round": {
          fontSize: "1.125rem",
          transform: `translateX(-3px)`,
          transition: "transform 0.5s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
        },

        "&:hover .material-icons-round, &:focus .material-icons-round": {
          transform: `translateX(-10px)`,
        },
      }}
    >
      <Icon sx={{ fontWeight: "bold" }}>
        <ArrowBackIcon />
      </Icon>{" "}
      Back
    </MKTypography>
  );
}

export default MKTypographyBack;
