// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Link from "@mui/material/Link";
//import Stack from "@mui/material/Stack";
/* eslint-disable prettier/prettier */
// Material Kit 2 React components
import MKBox from "components/MKBox";
//import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKTypographyBack from "components/MKTypography/MKTypographyBack";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import routes from "routes";
// Images
import bgImage from "assets/images/projects/dc.jpg";
import plazaImage from "assets/images/projects/plaza.jpg";

import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import MKButton from "components/MKButton";
import { useNavigate } from "react-router-dom";
//import { useState, useEffect } from "react";
//import axios from "axios";

function Plaza() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(""));

  const navigate = useNavigate();
  const redirectToHome = () => {
    navigate("/");
  };
  /*
  // const [refresh, setRefresh] = useState(true);
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    async function getProjects() {
      try {
        const res = await axios.get("http://localhost:8080/projects/3");
        setProjects(res.data);
      } catch (err) {
        console.log(err);
      }
    }
    getProjects();
  }, []);
  console.log(projects.description);
  */
  return (
    <MKBox component="header" position="relative">
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/al/contact-us",
          label: "Kontakt",
          color: "info",
        }}
        sticky
      />

      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.4)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container style={{ paddingTop: "100px" }}>
          <Grid
            style={{ maxWidth: "100%" }}
            paddingTop="10px"
            container
            item
            xs={12}
            md={7}
            lg={6}
            flexDirection="column"
            justifyContent="center"
          >
            {/* PAMPHLETI
          <center>
            <MKTypography
              variant="h2"
              color="white"
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Banka Kombetare Tregtare
            </MKTypography>{" "}
            <div style={{ position: "relative", left: "-10%" }}>
              <iframe
                allowFullScreen="allowFullScreen"
                scrolling="no"
                className="fp-iframe"
                src="https://heyzine.com/flip-book/df29b9a5bf.html"
                style={{
                  border: "1px solid lightgray",
                  width: "120%", // Adjusted to 100% width
                  height: "691px",
                }}
                title="BKT"
              ></iframe>
            </div>
          </center>
*/}
            <MKBox component="section" py={{ xs: 0, lg: 6 }}>
              <Container>
                <MKBox
                  width="100%"
                  bgColor="white"
                  borderRadius="xl"
                  shadow="xl"
                  mb={6}
                  sx={{ overflow: "hidden" }}
                >
                  <Grid container spacing={isSmallScreen ? 2 : 4}>
                    <Grid item xs={12} md={6}>
                      <img
                        src={plazaImage}
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        alt="Description of the image"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKTypography
                        variant="body1"
                        fontWeight="light"
                        color="text"
                        style={{ padding: "20px" }}
                      >
                        Ndërtimi i një Data Center dhe furnizimi i gjithë pajisjeve të
                        infrastrukurës (Servers, Firewall, Networking, Storage, etj.)
                        <br />
                        Sistemi i ftohjes
                        <br />
                        Panele elektrike
                        <br />
                        Dysheme teknike
                        <br />
                        Kondicionimi
                        <br />
                        UPS - APC
                        <br />
                        Akses Kontroll/Ndërhyrje
                        <br />
                        Sistemi i zbulimit dhe mbrojtjes nga zjarri
                        <br />
                        Rack - APC
                        <br />
                        PDU
                        <br />
                        BMS
                        <br />
                        HPE Servers, Firewall, Router, Switch, Acces Point, Storage
                        <br />
                        Klienti: Hotel Plaza Tirana Viti: 2015-2016
                        <br />
                        <br />
                      </MKTypography>
                    </Grid>
                  </Grid>
                </MKBox>

                <center>
                  <MKButton color="white" style={{ marginBottom: "30px" }} onClick={redirectToHome}>
                    <MKTypographyBack />
                  </MKButton>
                </center>
              </Container>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </MKBox>
  );
}

export default Plaza;

/*
 Ndërtimi i një Data Center dhe furnizimi i gjithë pajisjeve të
                        infrastrukurës (Servers, Firewall, Networking, Storage, etj.)
                        <br />
                        Sistemi i ftohjes
                        <br />
                        Panele elektrike
                        <br />
                        Dysheme teknike
                        <br />
                        Kondicionimi
                        <br />
                        UPS - APC
                        <br />
                        Akses Kontroll/Ndërhyrje
                        <br />
                        Sistemi i zbulimit dhe mbrojtjes nga zjarri
                        <br />
                        Rack - APC
                        <br />
                        PDU
                        <br />
                        BMS
                        <br />
                        HPE Servers, Firewall, Router, Switch, Acces Point, Storage
                        <br />
                        Klienti: Hotel Plaza Tirana Viti: 2015-2016
                        <br />
                        <br />
*/
