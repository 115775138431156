// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Link from "@mui/material/Link";
//import Stack from "@mui/material/Stack";
/* eslint-disable prettier/prettier */
// Material Kit 2 React components
import MKBox from "components/MKBox";
//import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKTypographyBack from "components/MKTypography/MKTypographyBack";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import routes from "routes";
// Images
import bgImage from "assets/images/projects/dc.jpg";
import masImage from "assets/images/projects/mas.jpg";

import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import MKButton from "components/MKButton";
function BKT() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(""));

  const navigate = useNavigate();
  const redirectToHome = () => {
    navigate("/");
  };

  return (
    <MKBox component="header" position="relative">
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/al/contact-us",
          label: "Kontakt",
          color: "info",
        }}
        sticky
      />

      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.4)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container style={{ paddingTop: "100px" }}>
          <Grid
            style={{ maxWidth: "100%" }}
            paddingTop="10px"
            container
            item
            xs={12}
            md={7}
            lg={6}
            flexDirection="column"
            justifyContent="center"
          >
            <MKBox component="section" py={{ xs: 0, lg: 6 }}>
              <Container>
                <MKBox
                  width="100%"
                  bgColor="white"
                  borderRadius="xl"
                  shadow="xl"
                  mb={6}
                  sx={{ overflow: "hidden" }}
                >
                  <Grid container spacing={isSmallScreen ? 2 : 4}>
                    <Grid item xs={12} md={6}>
                      <img
                        src={masImage}
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        alt="Description of the image"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} alignContent="center">
                      <MKTypography
                        variant="body1"
                        fontWeight="light"
                        color="text"
                        style={{ padding: "20px" }}
                      >
                        <br />
                        Qëllimi: <br />
                        Furnizimi dhe instalimi i pajisjeve dhe tabletëve për integrimin me
                        Platforma E-Learning, në shkollat e mesme. <br />
                        Klienti: Ministria e Arsimit Viti: 2014 <br />
                        <br />
                      </MKTypography>
                    </Grid>
                  </Grid>
                </MKBox>

                <center>
                  <MKButton color="white" style={{ marginBottom: "30px" }} onClick={redirectToHome}>
                    <MKTypographyBack />
                  </MKButton>
                </center>
              </Container>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </MKBox>
  );
}

export default BKT;
