// @mui material components
/* eslint-disable prettier/prettier */
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
/* eslint-disable no-unused-vars */
// Material Kit 2 React components
import MKBox from "components/MKBox";
//import MKInput from "components/MKInput";
//import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Card from "@mui/material/Card";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Button from "@mui/material/Button";
import DefaultFooter from "examples/Footers/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
// Images
import bgImage from "assets/images/examples/staff.png";
//import emailjs from "@emailjs/browser";
import Location from "components/Icons/Location";
import PhoneIcon from "components/Icons/PhoneIcon";
import EmailIcon from "components/Icons/EmailIcon";
function Contact() {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    alert(process.env.REACT_APP_EMAILJS_SERVICE_KEY);
    handleClick();

    //emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_KEY,  process.env.REACT_APP_EMAILJS_TEMPLATE_KEY,, e.target, process.env.REACT_APP_EMAILJS_PUBLIC_KEY);
  };
  return (
    <>
      <MKBox
        variant="gradient"
        bgColor="white"
        shadow="sm"
        py={0.25}
        sx={{
          position: "fixed",
          top: 0,
          zIndex: 1000,
          width: "100%",
        }}
      >
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/al/contact-us",
            label: "Kontakt",
            color: "dark",
          }}
          transparent
          relative
          dark
          center
        />
      </MKBox>
      <div>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: "100%" }}>
            Emaili u dergua me sukses!
          </Alert>
        </Snackbar>
      </div>
      {/*<MKBox variant="gradient" bgColor="dark" shadow="sm" py={0.25}>
          
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/al/contact-us",
            label: "kontakt",
            color: "info",
          }}
          transparent
          relative
          light
          center
        />
        
        </MKBox>*/}
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -1,
          mb: 4,
          pt: 1,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={{ xs: 0, lg: 6 }}>
          <MKBox component="section" py={{ xs: 0, lg: 6 }} style={{ paddingTop: "100px" }}>
            <Container>
              <Grid container item>
                <MKBox
                  width="100%"
                  bgColor="white"
                  borderRadius="xl"
                  shadow="xl"
                  mb={6}
                  sx={{ overflow: "hidden" }}
                >
                  <Grid container spacing={2}>
                    {/* Left side: Contact Information */}
                    <Grid
                      item
                      xs={12}
                      lg={5}
                      position="relative"
                      px={0}
                      sx={{
                        backgroundImage: ({
                          palette: { gradients },
                          functions: { rgba, linearGradient },
                        }) =>
                          `${linearGradient(
                            rgba(gradients.dark.main, 0.8),
                            rgba(gradients.dark.state, 0.8)
                          )}, url(${bgImage})`,
                        backgroundSize: "cover",
                      }}
                    >
                      <MKBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                        height="100%"
                      >
                        <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                          <MKTypography variant="h3" color="white" mb={1}>
                            Kontaktet
                          </MKTypography>
                          <MKBox display="flex" color="white" p={1} alignItems="center">
                            <MKTypography variant="button" color="white">
                              <Location width="25px" height="25px" color="white" />
                            </MKTypography>
                            <MKTypography
                              component="span"
                              variant="button"
                              color="white"
                              opacity={0.8}
                              ml={2}
                              fontWeight="regular"
                              fontSize="1rem"
                            >
                              “Murat Toptani” Str.Eurocol Center 8th Floor, 1001, Tirana, Albania
                            </MKTypography>
                          </MKBox>

                          <MKBox display="flex" p={1}>
                            <MKTypography variant="button" color="white">
                              <PhoneIcon width="25px" height="25px" color="white" />
                            </MKTypography>
                            <MKTypography
                              component="span"
                              variant="button"
                              color="white"
                              opacity={0.8}
                              ml={2}
                              fontWeight="regular"
                              fontSize="1rem"
                            >
                              +355 4 227 4672
                            </MKTypography>
                          </MKBox>
                          <MKBox display="flex" color="white" p={1}>
                            <MKTypography variant="button" color="white">
                              <EmailIcon width="25px" height="25px" color="white" />
                            </MKTypography>
                            <MKTypography
                              component="span"
                              variant="button"
                              color="white"
                              opacity={0.8}
                              ml={2}
                              fontWeight="regular"
                              fontSize="1rem"
                            >
                              abs@abs.al
                            </MKTypography>
                          </MKBox>
                        </MKBox>
                      </MKBox>
                    </Grid>

                    {/* Right side: Form */}
                    <Grid item xs={12} lg={7}>
                      <MKBox p={3}>
                        <MKTypography variant="h4" mb={2}>
                          Plotësoni fushat e më poshtme për të na dërguar email
                        </MKTypography>
                        <form onSubmit={sendEmail}>
                          <MKBox mb={2}>
                            <MKTypography variant="subtitle1" mb={1}>
                              Emri: *
                            </MKTypography>
                            <input
                              required
                              type="text"
                              placeholder=""
                              name="from_name"
                              id="from_name"
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                fontSize: "1rem",
                              }}
                            />
                          </MKBox>
                          <MKBox mb={2}>
                            <MKTypography variant="subtitle1" mb={1}>
                              Kompania:
                            </MKTypography>
                            <input
                              type="text"
                              placeholder=""
                              name="from_company"
                              id="from_company"
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                fontSize: "1rem",
                              }}
                            />
                          </MKBox>
                          <MKBox mb={2}>
                            <MKTypography variant="subtitle1" mb={1}>
                              Email: *
                            </MKTypography>
                            <input
                              required
                              type="email"
                              placeholder=""
                              name="from_email"
                              id="from_email"
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                fontSize: "1rem",
                              }}
                            />
                          </MKBox>
                          <MKBox mb={3}>
                            <MKTypography variant="subtitle1" mb={1}>
                              Mesazhi: *
                            </MKTypography>
                            <textarea
                              required
                              placeholder=""
                              rows="4"
                              name="message"
                              id="message"
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ccc",
                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                fontSize: "1rem",
                              }}
                            />
                          </MKBox>
                          <button
                            type="submit"
                            style={{
                              backgroundColor: "#344767",
                              color: "white",
                              padding: "10px 20px",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                              fontSize: "1rem",
                            }}
                          >
                            Dërgo
                          </button>
                        </form>
                      </MKBox>
                    </Grid>
                  </Grid>
                </MKBox>
              </Grid>
            </Container>
          </MKBox>
          <Container style={{ paddingTop: "0px" }}>
            <Grid container item>
              <MKBox
                width="100%"
                bgColor="white"
                borderRadius="xl"
                shadow="xl"
                mb={6}
                sx={{ overflow: "hidden" }}
              >
                <Grid item xs={12} lg={12} position="relative" px={0}>
                  <meta name="viewport" content="width=device-width, initial-scale=1" />
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1717.2246591061312!2d19.82250991642919!3d41.32739057390619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350311a7dcbf711%3A0x43685b5b3f0013d6!2sEurocol%20Business%20Center!5e0!3m2!1sen!2s!4v1730989323836!5m2!1sen!2s"
                    width="100%"
                    height="600"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    sandbox="allow-scripts allow-same-origin allow-popups"
                  ></iframe>
                </Grid>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6} style={{ paddingTop: "0px" }}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Contact;
