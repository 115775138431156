/* eslint-disable prettier/prettier */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
//import MKButton from "components/MKButton";

// Material Kit 2 React examples
//import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
/*
// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";
*/
// Routes
//import routes from "routes";
import footerRoutes from "footer.routes";
import MKTypographyBack from "components/MKTypography/MKTypographyBack";
import { useNavigate } from "react-router-dom";

// Images
import bgImage from "assets/images/examples/city-profile.jpg";

function Maintenance() {
  const navigate = useNavigate();
  const redirectToHome = () => {
    navigate("/");
  };

  return (
    <>
      <MKBox
        variant="gradient"
        bgColor="white"
        shadow="sm"
        py={0.0}
        sx={{
          position: "fixed",
          top: 0,
          zIndex: 1000,
          width: "100%",
        }}
      >
        {/*
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/al/contact-us",
            label: "Kontakt",
            color: "dark",
          }}
          transparent
          relative
          dark
          center
        />*/}
      </MKBox>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              style={{ paddingTop: "100px" }}
              variant="h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              We're currently undergoing maintenance. Please check back soon..
            </MKTypography>
            <center>
              <MKButton color="white" style={{ marginTop: "30px" }} onClick={redirectToHome}>
                <MKTypographyBack />
              </MKButton>
            </center>

            {/*
            <MKTypography variant="h6" color="white" mt={8} mb={1}>
              {" "}
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-facebook" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-instagram" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                <i className="fab fa-twitter" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="#">
                <i className="fab fa-google-plus" />
            </MKTypography>
            </MKBox>*/}
          </Grid>
        </Container>
      </MKBox>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Maintenance;
