/* eslint-disable prettier/prettier */
//Images:
import plazza from "assets/images/projects/plaza.jpg";
import bkt from "assets/images/projects/bkt.jpg";
import mas from "assets/images/projects/mas.jpg";
import oshee from "assets/images/projects/oshee2.jpg";
import mfe from "assets/images/projects/mfe.jpg";
import rash from "assets/images/projects/rash.png";
import akshi from "assets/images/projects/akshi.jpg";

export default [
  {
    title: "Projektet",
    description: "Disa nga projektet kryesore ne vitet e fundit",
    items: [
      {
        image: plazza,
        name: "PLAZA TIRANA HOTEL",
        route: "/al/project/plaza",
        //count: 8,
        //pro: true,
      },
      {
        image: bkt,
        name: "BKT",
        route: "/al/project/bkt",
        //count: 8,
        //pro: true,
      },
      {
        image: mas,
        name: "MINISTRIA E ARSIMIT DHE SPORTEVE",
        route: "/al/project/mas",
        //count: 8,
        //pro: true,
      },
      {
        image: oshee,
        name: "OSHEE",
        route: "/al/project/oshee",
        //count: 8,
        //pro: true,
      },
      {
        image: mfe,
        name: "MINISTRIA E FINANCAVE DHE EKONOMISE",
        route: "/al/project/mfe",
        //count: 8,
        //pro: true,
      },
      {
        image: rash,
        name: "Rrjeti Akademik Shqiptare",
        route: "/al/project/rash",
        //count: 8,
        //pro: true,
      },
      {
        image: akshi,
        name: "Agjensia Kombetare e Shoqerise se Informacionit",
        // count: 10,
        route: "/al/project/akshi",
      },
    ],
  } /*
  
  ,
  {
    title: "Navigation",
    description: "30+ components that will help go through the pages",
    items: [
      {
        image: `${imagesPrefix}/navbars.jpg`,
        name: "Navbars",
        count: 4,
        route: "/sections/navigation/navbars",
      },
      {
        image: `${imagesPrefix}/nav-tabs.jpg`,
        name: "Nav Tabs",
        count: 2,
        route: "/sections/navigation/nav-tabs",
      },
      {
        image: `${imagesPrefix}/pagination.jpg`,
        name: "Pagination",
        count: 3,
        route: "/sections/navigation/pagination",
      },
    ],
  },
  
    ],
  },*/,
];
